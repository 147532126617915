
// import { useState } from 'react';
import './App.css';
// import { Link } from 'react-router-dom';

function App() {
  // const [val, setVal] =useState("")
  return (
    <>
    </>
    // <div className='page'>
    //   <span>វាយ លេខដែលអ្នកពេញចិត្ត 0 1 2 3 4 5 6 7 8 9 </span>
    // <div className='form'>
    //   <div className='InputContainer'>
    //   <input placeholder='Value' onChange={ (e) => setVal(e.target.value)} />

    //   </div>
    // <Link to={`https://link.payway.com.kh/aba?id=3066853E4648&code=226576&acc=500434165&amount=${val}&dynamic=true`} className='button2'> SEND</Link>
    // </div>
    // </div>
  );
}

export default App;
